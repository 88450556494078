export default (function () {
    document.addEventListener("DOMContentLoaded", function () {
        // make it as accordion for smaller screens
        if (window.innerWidth < 992) {

            // close all inner dropdowns when parent is closed
            document.querySelectorAll('.navbar .dropdown').forEach(function (everydropdown) {
                everydropdown.addEventListener('hidden.bs.dropdown', function () {
                    // after dropdown is hidden, then find all submenus
                    this.querySelectorAll('.submenu').forEach(function (everysubmenu) {
                        // hide every submenu as well
                        everysubmenu.style.display = 'none';
                    });
                })
            });

            document.querySelectorAll('.dropdown-menu a').forEach(function (element) {
                element.addEventListener('click', function (e) {
                    let nextEl = this.nextElementSibling;
                    if (nextEl && nextEl.classList.contains('submenu')) {
                        // prevent opening link if link needs to open dropdown
                        e.preventDefault();
                        if (nextEl.style.display == 'block') {
                            nextEl.style.display = 'none';
                        } else {
                            nextEl.style.display = 'block';
                        }

                    }
                });
            })
        }
        // end if innerWidth
    });
    //$(document).ready(function () {
    //    $('.dropdown').hover(function () {
    //        if (!isTouchEvent()) {
    //            $(this).find('.dropdown-menu:first-child')
    //                .stop(true, true).delay(100).fadeIn(200);
    //        }
    //    }, function () {
    //        if (!isTouchEvent()) {
    //            $(this).find('.dropdown-menu:first-child')
    //                .stop(true, true).delay(100).fadeOut(200);
    //        }
    //    });
    //});
    function isTouchEvent() {
        return 'ontouchstart' in document.documentElement
            || navigator.maxTouchPoints > 0
            || navigator.msMaxTouchPoints > 0;
    }
    // DOMContentLoaded  end
})();