/*jshint esversion: 6 */
export default (function () {
	const selectRating = document.getElementsByClassName('star-ratings')[0];

	//only init the carousel arrows if our scrolling container has a scrollbar
	//otherwise, hide the arrows
	if (typeof selectRating !== 'undefined' || selectRating != null) {
		initRatings();
	}

	function initRatings() {
		const stars = [].slice.apply(selectRating.getElementsByClassName('star-ratings__star'));
		const ratingInput = document.getElementById('Rating');

		selectRating.addEventListener('mouseout', e => {
			stars.forEach((item, index) => {
				item.classList.remove('star-ratings__star-hover');
			});
		});

		stars.forEach((item, index) => {

			item.addEventListener('mouseover', e => {
				console.log(e);
				e.preventDefault();
				const self = e.currentTarget;

				var prev = getPreviousSiblings(self);

				self.classList.add('star-ratings__star-hover');

				prev.forEach((item, index) => {
					item.classList.add('star-ratings__star-hover');
				});
			});

			item.addEventListener('click', e => {
				const self = e.currentTarget;

				var prev = getPreviousSiblings(self);
				var next = getNextSiblings(self);

				self.classList.add('star-ratings__star-on');

				prev.forEach((item, index) => {
					item.classList.add('star-ratings__star-on');
				});

				next.forEach((item, index) => {
					item.classList.remove('star-ratings__star-on');
				});

				ratingInput.value = self.dataset.rating;
			});
		});
	}

	function getPreviousSiblings(elem) {
		var sibs = [];
		while (elem = elem.previousSibling) {
			if (elem.nodeType === 3) continue; // text node
			sibs.push(elem);
		}
		return sibs;
	} 

	function getNextSiblings(elem) {
		var sibs = [];
		while (elem = elem.nextSibling) {
			if (elem.nodeType === 3) continue; // text node
			sibs.push(elem);
		}
		return sibs;
	}

	//$('.rating-input').each(function () {
	//	var $container = $(this);
	//	var $options = $('li', $container);
	//	var input = $('input[name$="Rating"]', $container.parent());
	//	//$container.hover(function() {
	//	$options.each(function () {
	//		$(this).on('mouseover', function (e) {
	//			$options.removeClass('hover');
	//			$(this).addClass('hover').prevAll('li').addClass('hover');
	//		}).on('mouseout', function () {
	//			$options.removeClass('hover');
	//		}).on('click', function (e) {
	//			$options.removeClass('on');
	//			$(this).addClass('on').prevAll('li').addClass('on');
	//			input.val($(this).index() + 1);
	//			//console.log(input.val());
	//		});
	//		$container.addClass('hover');
	//	});
	//	//}, function() {
	//	//	$container.removeClass('hover');
	//	//	$options.removeClass('hover');
	//	//});

	//});
})();