export default (function () {
    const dropdownItems = [].slice.apply(document.getElementsByClassName('account-downloads__item--dropdown'));

    dropdownItems.forEach((item, index) => {
        item.addEventListener('click', e => {
            if (e.target.classList.contains('account-downloads__heading') && !e.target.classList.contains('account-downloads__heading--alt')) {
                const self = e.target.parentElement;
                if (self.classList.contains('account-downloads__item--dropdown-active')) {
                    self.classList.remove('account-downloads__item--dropdown-active');
                } else {
                    self.classList.add('account-downloads__item--dropdown-active');

                    const siblings = dropdownItems.filter((item, itemIndex) => {
                        return itemIndex !== index;
                    });

                    siblings.forEach(item => {
                        if (item.classList.contains('account-downloads__item--dropdown-active')) {
                            item.classList.remove('account-downloads__item--dropdown-active');
                        }
                    });

                }
            }
        });
    });

    const facetDropDownItems = [].slice.apply(document.getElementsByClassName('account-downloads__facet-item--dropdown'));

    facetDropDownItems.forEach((item, index) => {
        item.addEventListener('click', e => {
            if (e.target.classList.contains('account-downloads__heading') && e.target.classList.contains('account-downloads__heading--alt')) {
                const self = e.target.parentElement;
                if (self.classList.contains('account-downloads__facet-item--dropdown-active')) {
                    self.classList.remove('account-downloads__facet-item--dropdown-active');
                } else {
                    self.classList.add('account-downloads__facet-item--dropdown-active');

                    const siblings = facetDropDownItems.filter((item, itemIndex) => {
                        return itemIndex !== index;
                    });

                    siblings.forEach(item => {
                        if (item.classList.contains('account-downloads__facet-item--dropdown-active')) {
                            item.classList.remove('account-downloads__facet-item--dropdown-active');
                        }
                    });

                }
            }
        });
    });
})();