export default (function () {
    const axios = require('axios');
    const parse = require('csv-parse');
    require('formdata-polyfill');

    // Utility Selectors
    const quickCartSelector = ".account-business__file-button";
    const quickUPCSelector = ".account-business__quick-order-form--upc";
    const quickStyleSelector = ".account-business__quick-order-form--style";
    const quickStyleFormSelector = ".account-business__quick-order-form--style-selection";

    // Messages
    const quickOrderMessageSelector = '.account-business__file-message';
    const quickUPCMessageSelector = '.account-business__upc-message';
    const quickStyleMessageSelector = '.account-business__style-message';

    const cartValidationUrl = "/umbraco/api/productinfo/productcart";
    //const productStylesUrl = "/umbraco/api/productinfo/productstyle";
    const productStylesUrl = "/umbraco/api/productinfo/productstyleextended";

    let quickOrderInput = document.querySelector(quickCartSelector);
    let quickUPCButton = document.querySelector(quickUPCSelector);
    let quickStyleButton = document.querySelector(quickStyleSelector);

    let quickStyleForm = document.querySelector(quickStyleFormSelector);

    let messageContainer = document.querySelector(quickOrderMessageSelector);
    let messageUPCContainer = document.querySelector(quickUPCMessageSelector);
    let messageStyleContainer = document.querySelector(quickStyleMessageSelector);

    if (quickOrderInput === null) {
        return false;
    }

    let cartRequests = [];

    if (typeof quickOrderInput !== 'undefined' && quickOrderInput !== null) {
        quickOrderInput.addEventListener('change', function (e) {
            UpdateMessage("Processing...", messageContainer);

            var curFiles = quickOrderInput.files;
            if (curFiles.length === 0) {
                UpdateMessage("No file found.");
            }
            else {
                var reader = new FileReader();

                reader.onload = function (e) {
                    var csv = e.target.result;

                    parse(csv, function (err, data, { lines, records }) {
                        if (typeof err === 'undefined') {
                            CheckRecords(data, true, messageContainer);
                        } else {
                            UpdateMessage("An error occurred processing your file. " + err);
                        }
                    });
                };

                // start reading the file. When it is done, calls the onload event defined above.
                reader.readAsText(curFiles[0]);
            }
        });
    }

    if (typeof quickUPCButton !== 'undefined' && quickUPCButton !== null) {
        quickUPCButton.addEventListener('submit', function (e) {
            e.preventDefault();

            let form = e.target;
            let formData = new FormData(form);

            console.log(form);

            const upcPrefix = formData.get('upc-prefix');
            const upcSuffix = formData.get('upc-suffix');
            const quantity = formData.get('upc-quantity');

            if (upcPrefix === "") {
                UpdateMessage("UPC Prefix not valid.", messageUPCContainer);
                return false;
            }

            if (upcSuffix === "") {
                UpdateMessage("UPC Suffix not valid.", messageUPCContainer);
                return false;
            }

            if (quantity === "") {
                UpdateMessage("Quantity not valid.", messageUPCContainer);
                return false;
            }

            UpdateMessage("Processing...", messageUPCContainer);

            // Mimic the CSV Upload Structure
            let manualRecords = [
                ["UPC", "Quantity"],
                [upcPrefix + '' + upcSuffix, quantity]
            ];

            CheckRecords(manualRecords, false, messageUPCContainer);
        });
    }

    if (typeof quickStyleButton !== 'undefined' && quickStyleButton !== null) {
        quickStyleButton.addEventListener('submit', function (e) {
            e.preventDefault();

            let form = e.target;
            let formData = new FormData(form);

            console.log(form);

            const stylecode = formData.get('style-item').toUpperCase();

            UpdateMessage("Processing...", messageStyleContainer);

            GetProductStyles(stylecode, messageStyleContainer);
        });
    }
    
    if (typeof quickStyleForm !== 'undefined' && quickStyleForm !== null) {
        quickStyleForm.addEventListener('submit', function (event) {
            event.preventDefault();

            let form = event.target;
            let formData = new FormData(form);

            // Mimic the CSV Upload Structure
            let manualRecords = [
                ["UPC", "Quantity"]
            ];

            let entries = formData.entries();

            console.log(entries);

            // or, if you are really into compact code
            var es, e, pair;
            for (es = formData.entries(); !(e = es.next()).done && (pair = e.value);) {
                console.log(pair[0] + ', ' + pair[1]);

                let upc = pair[0].replace("item_", "");
                let quantity = pair[1];

                if (quantity !== null && quantity !== "") {
                    manualRecords.push([upc, quantity]);
                }
            }

            CheckRecords(manualRecords, true, messageStyleContainer);
        });
    }

    function UpdateMessage(message, container) {
        container.innerHTML = message;
    }

    function CheckRecords(records, includeCheckDigit, container) {
        // Skip the header record
        let requests = [];

        for (let i = 1; i < records.length; i++) {
            let record = records[i];

            let upc = record[0];
            let quantity = record[1];

            requests.push(axios.get(cartValidationUrl, {
                params: {
                    upc: upc,
                    IncludesCheckDigit: includeCheckDigit
                }
            }));

            cartRequests[upc] = quantity;
        }

        UpdateMessage("Checking your selected items. Please wait...", container);

        Promise.all(requests)
            .then(function (response) {
                for (let j = 0; j < response.length; j++) {
                    if (typeof response[j].data !== 'undefined') {
                        UpdateCart(response[j].data, includeCheckDigit);
                    }
                }
                if (typeof quickStyleForm !== 'undefined' && quickStyleForm !== null) {
                    quickStyleForm.classList.add('account-business__quick-order-form--hidden');
                }
                UpdateMessage("<span class='coastal--type-body1'><strong style='color: green;'>Cart updated.</strong> You may proceed to checkout.</span>", container);
            })
            .catch(function (error) {
                UpdateMessage("No Matching Products Were Found.", container);
            });
    }

    function UpdateCart(item, includeCheckDigit) {
        if (item === null) {
            return;
        }
        else {
            let upc = item.UPC_Code_with_Check_Digit;

            if (includeCheckDigit === false) {
                upc = upc.slice(0, -1);
            }

            let quantity = cartRequests[upc];

            //Dispatch an event
            var evt = new CustomEvent("coastal.addtocart", {
                detail: {
                    product: item,
                    quantity: quantity
                }
            });

            window.dispatchEvent(evt);
        }
    }

    function GetProductStyles(stylecode, container) {
        let request = axios.get(productStylesUrl, {
            params: {
                stylecode: stylecode
            }
        });

        request.then(function (response) {
            console.log(response.data);

            quickStyleForm.classList.remove('account-business__quick-order-form--hidden');

            if (response.data !== null) {
                UpdateStylesTable(response.data);
                UpdateMessage("", container);
            } else {
                UpdateMessage("No matching products could be found.", container);
                quickStyleForm.classList.add('account-business__quick-order-form--hidden');
            }
        }).catch(function (error) {
                UpdateMessage("An error occurred processing. Please verify your style code.", container);
        });
    }

    // Expects list of colors with matching size keys and UPCs
    /* 
         {
            "Black": {
            "5/8\" x 12\"": "076484034206",
            "5/8\" x 14\"": "076484034404",
            "5/8\" x 16\"": "076484034602"
            },
            "Red": {
            "5/8\" x 12\"": "076484034213",
            "5/8\" x 14\"": "076484034411",
            "5/8\" x 16\"": "076484034619"
            }
         }
     */

    function UpdateStylesTable(ary) {
        let tableHeader = document.querySelector('.style-table__head');
        let tableBody = document.querySelector('.style-table__body');

        let thead = "<td>Item Number</td><td>Size</td><td>Color Code</td><td>Color Description</td><td>UPC</td><td>Quantity</td>";
        let tbody = "";

        for (var i = 0; i < ary.length; i++) {
            tbody += "<tr>";
            tbody += "<td>" + ary[i].ItemNumber + "</td>";
            tbody += "<td>" + ary[i].Size + "</td>";
            tbody += "<td>" + ary[i].ColorCode + "</td>";
            tbody += "<td>" + ary[i].ColorDescription + "</td>";
            tbody += "<td>" + ary[i].UPC + "</td>";
            var inputname = ary[i].UPC
            tbody += "<td>" + "<input name=\"item_" + inputname + "\" type=\"number\" min=\"0\" max=\"999\" maxlength=\"3\" size=\"2\" />" + "</td>";

            tbody += "</tr>";
        }


        tableHeader.innerHTML = thead;
        tableBody.innerHTML = tbody;

    }
})();