export default (function () {
    const dropdownItems = [].slice.apply(document.getElementsByClassName('site-navigation__item--dropdown'));

    dropdownItems.forEach((item, index) => {
        item.addEventListener('click', e => {
            if (e.target.classList.contains('site-navigation__item--dropdown')) {
                const self = e.target;
                if (self.classList.contains('site-navigation__item--dropdown-active')) {
                    self.classList.remove('site-navigation__item--dropdown-active');
                } else {
                    self.classList.add('site-navigation__item--dropdown-active');

                    const siblings = dropdownItems.filter((item, itemIndex) => {
                        return itemIndex !== index; 
                    });

                    siblings.forEach(item => {
                        if (item.classList.contains('site-navigation__item--dropdown-active')) {
                            item.classList.remove('site-navigation__item--dropdown-active');
                        }
                    });

                }
            }
        });
    });
})();