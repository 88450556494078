/*jshint esversion: 6 */
export default (function () {
	const scrollingContainer = document.getElementsByClassName('product-details__preview-images--scrolling-container')[0];

	//only init the carousel arrows if our scrolling container has a scrollbar
	//otherwise, hide the arrows
	if (typeof scrollingContainer !== 'undefined' || scrollingContainer != null) {
		initCarousel();
	}

	function initCarousel() {
		const carouselItems = [].slice.apply(scrollingContainer.getElementsByClassName('product-details__preview-images--image'));
        const carouselVideos = [].slice.apply(scrollingContainer.getElementsByClassName('product-details__preview-images--video'));
		const leftArrow = document.getElementsByClassName('product-details__preview-images--chevron-left')[0];
		const rightArrow = document.getElementsByClassName('product-details__preview-images--chevron-right')[0];
		const mainProductImage = document.getElementsByClassName('product-details__product-image')[0];
        const scrollJump = 2.5;

        let currentVideoContainer = null;

		if (carouselItems.length <= 4){
			leftArrow.style.display = "none";
			rightArrow.style.display = "none";
		}

		leftArrow.addEventListener('click', e => {
			e.preventDefault();
			var carouselItemWidth = carouselItems[0].offsetWidth;
			var carouselScrollPos = scrollingContainer.scrollLeft;
			var scrollTo = carouselScrollPos - (carouselItemWidth * scrollJump);

			if (scrollTo < 0) {
				scrollTo = 0;
			}

			scrollingContainer.scrollLeft = scrollTo;
		});

		rightArrow.addEventListener('click', e => {
			e.preventDefault();
			var carouselItemWidth = carouselItems[0].offsetWidth;
			var carouselScrollPos = scrollingContainer.scrollLeft;
			var scrollTo = carouselScrollPos + (carouselItemWidth * scrollJump);

			if (scrollTo > scrollingContainer.scrollWidth) {
				scrollTo = scrollingContainer.scrollWidth;
			}

			scrollingContainer.scrollLeft = scrollTo;
		});

		//on click, load main image and mark thumbnail as active
		carouselItems.forEach((item, index) => {
			item.addEventListener('click', e => {
				e.preventDefault();

				carouselItems.forEach((item, i) => {
					item.classList.remove('product-details__preview-images--image-active');
                });

                carouselVideos.forEach((item, i) => {
                    item.classList.remove('product-details__preview-images--video-active');
                });

				e.currentTarget.classList.add("product-details__preview-images--image-active");
                mainProductImage.src = e.currentTarget.src;

                mainProductImage.style.display = "block";

                if (currentVideoContainer !== null) {
                    currentVideoContainer.remove();
                }
			});
        });

        //on click, load main image and mark thumbnail as active
        carouselVideos.forEach((item, index) => {
            item.addEventListener('click', e => {
                e.preventDefault();

                carouselItems.forEach((item, i) => {
                    item.classList.remove('product-details__preview-images--image-active');
                });

                carouselVideos.forEach((item, i) => {
                    item.classList.remove('product-details__preview-images--video-active');
                });

                e.currentTarget.classList.add("product-details__preview-images--video-active");
                mainProductImage.style.display = "none";

                let videoID = e.currentTarget.dataset.videoid;

                currentVideoContainer = document.createElement("iframe");
                currentVideoContainer.src = "//www.youtube.com/embed/" + videoID;

                mainProductImage.parentNode.insertBefore(currentVideoContainer, mainProductImage.nextSibling);
            });
        });
	}
})();