/*jshint esversion: 6 */
export default (function () {
	const scrollingContainer = document.getElementsByClassName('homepage__brands-images--scrolling-container')[0];
	
	//only init the carousel arrows if our scrolling container has a scrollbar
	//otherwise, hide the arrows
	if (typeof scrollingContainer !== 'undefined' || scrollingContainer != null) {
		initCarousel();
	}

	function initCarousel() {
		const carouselItems = [].slice.apply(scrollingContainer.getElementsByClassName('homepage__preview-images--image'));
		const leftArrow = document.getElementsByClassName('homepage__brands-images--chevron-left')[0];
		const rightArrow = document.getElementsByClassName('homepage__brands-images--chevron-right')[0];

		//Code added to duplicate brand divs
		const scroll = document.querySelector('.homepage__brands-images-scroll');
		const table1 = scroll.querySelector('table');
		const table2 = table1.cloneNode(true);
		scroll.appendChild(table2);

		const options = {
			root: scroll,
			rootMargin: '0px',
			threshold: 0
		}

		const callback = (entries) => {
			if (!entries[0].isIntersecting) {
				// table1 is out of bounds, we can scroll back to it
				scroll.scrollLeft = 0;
			}
		}

		const observer = new IntersectionObserver(callback, options);
		observer.observe(table1);
		
		if (carouselItems.length <= 4) {
			leftArrow.style.display = "none";
			rightArrow.style.display = "none";
		}
		
		rightArrow.addEventListener('mouseover', e => {
			e.preventDefault();
			document.getElementsByClassName('homepage__brands-images-scroll')[0].classList.add('homepage__brands-images-scroll-start-animate');
		});
		rightArrow.addEventListener('mouseout', e => {
			e.preventDefault();
			document.getElementsByClassName('homepage__brands-images-scroll')[0].classList.remove('homepage__brands-images-scroll-start-animate');
		});
		leftArrow.addEventListener('mouseover', e => {
			e.preventDefault();
			document.getElementsByClassName('homepage__brands-images-scroll')[0].classList.add('homepage__brands-images-scroll-start-animate-reverse');
		});
		leftArrow.addEventListener('mouseout', e => {
			e.preventDefault();
			document.getElementsByClassName('homepage__brands-images-scroll')[0].classList.remove('homepage__brands-images-scroll-start-animate-reverse');
		});
	}
})();