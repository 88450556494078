import Diacart from "diacart";
import { html, render } from "lit-html"; // in case you want to change the default templates
import { relativeTimeRounding } from "moment";
const cachios = require('cachios');

export default (function () {
    window.coastal = typeof window.coastal === "undefined" ? {} : window.coastal;
    const addToCartButtons = [].slice.apply(document.getElementsByClassName('product-details__cart-btn'));
    const mailingList = window.$('#mailing-list');
    const paymentOptions = window.$('input[name="billing-choice"]');
    const ccDivs = [].slice.apply(document.getElementsByClassName('card-information'));
    const checkoutForm = window.$('#checkout-form');
    const checkoutElem = document.getElementById('checkout-form');
    const cardFirstName = window.$('#card-firstname');
    const cardLastName = window.$('#card-lastname');
    const cardNumber = window.$('#card-number');
    const expMonth = window.$('#card-month');
    const expYear = window.$('#card-year');
    const cvv = window.$('#card-cvv');
    let applyPromo = false;

    let cartName = typeof window.coastal.legacyumbracoid === "undefined" ? "diacart" : "diacart" + window.coastal.legacyumbracoid;
    let accountId = typeof window.coastal.legacyumbracoid === "undefined" ? "" : "" + window.coastal.legacyumbracoid;

    let cartStorage = window.localStorage;
    let isCartSync = false;

    // Page defined as to whether or not to submit the order
    const isFinalStep = typeof window.coastal.submitOrder === "undefined" ? false : window.coastal.submitOrder;

    let isSubmitting = false;

    // All parameters are optional. You can omit the option parameter in Diacart, then the default options will be used.
    const diacart = new Diacart({
        name: cartName,
        title: "My Shopping Cart",
        subtotalPriceText: "Subtotal",
        totalPriceText: "Total",
        discountText: "Discount",
        discount: 0,
        promoCode: "",
        taxText: "Tax",
        tax: 0,
        totalQuantityText: "Quantity",
        removeFromCartBtnText: "Remove",
        emptyCartText: "Your shopping cart is currently empty.",
        orderBtnText: typeof window.coastal.nextCartButtonText === "undefined" ? "" : window.coastal.nextCartButtonText,
        orderBtnUrl: typeof window.coastal.nextCartUrl === "undefined" ? "" : window.coastal.nextCartUrl,
        clearBtnText: "Clear cart",
        finalStep: isFinalStep,
        currency: "$",
        groupBy: "id", // 'null', 'undefined' or false to not group

        itemHasQuantity: true, // 'true' or 'false'
        itemHasPrice: true, // 'true' or 'false'

        // selectors
        containerSelector: "[data-diacart-container]", // here will be rendered template
        miniContainerSelector: "[data-diacart-mini-container]", // here will be rendered miniTemplate
        totalPriceContainerSelector: "[data-diacart-total-price-container]", // here will be rendered totalPriceTemplate
        totalQuantityContainerSelector: "[data-diacart-total-quantity-container]", // here will be rendered totalQuantityTemplate

        addToCartBtnSelector: "[data-diacart-add-to-cart]", // the 'add' function will be attached to them
        removeFromCartBtnSelector: "[data-diacart-remove-from-cart]", // the 'remove' function will be attached to them
        quantityInputSelector: "[data-diacart-quantity-input]", // Does not work on 'Select' options
        orderBtnSelector: "[data-diacart-order]", // the 'order' function will be attached to them
        clearBtnSelector: "[data-diacart-clear]", // the 'clear' function will be attached to them

        // template rendering functions
        templateTagFunction: html, // MUST specify these parameters if you want to override templates!
        templateRenderFunction: render, // MUST specify these parameters if you want to override templates!

        // template functions
        template: diacartObject => {
            const { options, storage, totalPrice } = diacartObject;
            return html`
    <div class="cart diacart-wrapper">
		<div class="diacart coastal--grid">
			<div class="coastal--row cart__table-titles">
				<div class="coastal--col-md-5 coastal--col-lg-5 coastal--col-xl-5">
					<h4 class="coastal--type-h4 cart__important-text">${options.title || ""}</h4>
				</div>
				<div class="coastal--col-md-3 coastal--col-lg-3 coastal--col-xl-2 cart__table-small-headers">
					<p class="cart__small-header cart__small-header--price">Price</p>
					<p class="cart__small-header">Quantity</p>
				</div>
			</div>
			<div class="cart__clear diacart-grid-col diacart-grid-col--auto diacart__header-col${!storage.length
                    ? " diacart-hidden"
                    : ""}">
				<button class="diacart__clear-btn diacart-btn diacart-btn--default diacart-btn--medium"
						data-diacart-clear>
					${options.clearBtnText || ""}
				</button>
			</div>
		</div>
		<div class="diacart__items coastal--grid">
			${!storage.length
                    ? html`
			<div class="diacart-empty coastal--row">
				<h2 class="diacart-empty__title coastal--type-h3 coastal--col-lg-8 coastal--col-xl-7">
					${options.emptyCartText}
				</h2>
			</div>
			<div class="coastal--grid">
				`
                    : storage.map((item, id) =>
                        options.itemTemplate({ id, item, options })
                    )}
			</div>
		</div>

		<div class="coastal--grid">
		    <div class="coastal--row">
                <div class="coastal--col-md-4 coastal--col-lg-4 coastal--col-xl-4">
                ${!options.finalStep
                    ? html`
                        <div class="form__field form__field--float" id="promo-code-container">
                            <input type="text" name="promo-code" id="promo-code" class="text form__input" value="" />
                            <label for="promo-code" class="form__label" id="promo-code-label">
								Promo Code
                            </label>
						</div>
                        <div id="promo-error" style="display:none">The promo code you entered is invalid</div>
                        <a href="#" class="coastal--type-button cart__button-checkout cart__button-checkout--inverse cart-item-added__button" id="apply-promo-code">Apply</a>
                        `
                    : html`
                        <div class="form__field form__field--float">
                            <textarea type="text" name="order-note" id="order-note" class="text form__input" value="" maxlength="500"></textarea>
                            <label for="order-note" class="form__label">
								Additional Notes
                            </label>
						</div>
                        `}
                        <div>
                            <h2 class="coastal--type-h6">If ordering through a Distributor Drop Ship account, please note:</h2>
                            <p class="coastal--type-body2">You will be invoiced by your distributor. Pricing shown is suggestive dealer pricing and actual total may vary from what is shown. </p>
                            <p class="coastal--type-body2">Your order will be submitted for approval to the distributor prior to shipping from the Coastal Pet warehouse. <strong>A $15 small order fee will be applied to any orders under $225 ($150 if you are part of our Authorized Dealer program).</strong></p>
                        </div>
                </div>
			    <div class="coastal--col-md-4 coastal--col-lg-4 coastal--col-xl-3 cart__finalize diacart__footer${!storage.length ? " diacart-hidden" : ""}">
				    <div class="diacart__footer-container">
					    <div class="cart__total">
						    <p class="coastal--type-h5 cart__subtotal-text cart__subtotal-text--gray">
							    ${options.subtotalPriceText
                                    ? options.subtotalPriceText + ": "
                                    : ""}
						                    </p>
						                    <p class="coastal--type-h5 cart__subtotal-text cart__subtotal-text--blue">
							                    ${options.subtotalPriceTemplate({
                                        options,
                                        totalPrice
                                    })}
						    </p>
					    </div>
                        ${options.discount > 0 ? html`<div class="cart__total">
						    <p class="coastal--type-h5 cart__subtotal-text cart__subtotal-text--gray">
							    ${options.discountText
                                    ? options.discountText + " (" + options.promoCode + "): "
                                    : ""}
						            </p>
						            <p class="coastal--type-h5 cart__subtotal-text cart__subtotal-text--blue">
							            ${options.discountTemplate({
                                        options
                                    })}
						    </p>
					    </div>` : ""
                        }
                        ${!window.coastal.reviewcart
                        ? ""
                        : html`
                            <div class="cart__total">
						        <p class="coastal--type-h5 cart__subtotal-text cart__subtotal-text--gray">
                                    ${options.taxText
                                    ? options.taxText + ": "
                                    : ""}
						        </p>
						        <p class="coastal--type-h5 cart__subtotal-text cart__subtotal-text--blue">
							        ${options.taxTemplate({
                                        options
                                    })}
					        </div>
					        <div class="cart__total">
						        <p class="coastal--type-h5 cart__subtotal-text cart__subtotal-text--gray">
							        ${options.totalPriceText
                                        ? options.totalPriceText + ": "
                                        : ""}
						        </p>
						        <p class="coastal--type-h5 cart__subtotal-text cart__subtotal-text--blue">
							        ${options.totalPriceTemplate({
                                        options,
                                        totalPrice
                                    })}
						        </p>
					        </div>
                            `}
                            ${!storage.length
                    ? ""
                    : html`
					    <!-- div class="cart__total">
						    <p class="coastal--type-h5 cart__subtotal-text cart__subtotal-text--gray">Shipping</p>
						    <p class="coastal--type-caption cart__subtotal-text--gray">(Flat Rate)</p>
						    <p class="coastal--type-h5 cart__subtotal-text cart__subtotal-text--gray">: </p>
						    <p class="coastal--type-h5 cart__subtotal-text cart__subtotal-text--blue">$5.00</p>
					    </div -->
					    <a href="${options.orderBtnUrl || ""}" class="cart__button-checkout coastal--type-button diacart__order-btn diacart-btn diacart-btn--primary diacart-btn--medium"
							    data-diacart-order>
						    ${options.orderBtnText || ""}
					    </a>
                        `}
                    <!--<button class="coastal--type-button cart__button-checkout cart__button-checkout--inverse cart-item-added__button" onclick="window.history.back();">Continue Shopping</button>-->
				    </div>
			    </div>
		    </div>
        </div>
	</div>
    `;
        }, // cart template as a function
        itemTemplate: ({ id, item, options }) => {
            return html`

    <div class="coastal--row cart-product__row">
        <div class="coastal--col-md-5 coastal--col-lg-5 coastal--col-xl-5 cart-product__flex-row">
                ${!item.image
                    ? ""
                    : html`
            <img class="diacart-item__image cart-product__image" src="${item.image || ""}" alt="Product image" />
              `}
            <div class="cart-product__text">
                <p class="cart-product__description cart__important-text">
                    <a href="${item.link || ""}" class="diacart-item__title">
                        ${item.name || ""}
                    </a>
                </p>
                <p class="coastal--type-body2">
				    <strong>UPC:</strong> 
				    <span class="product-details__upc">${item.id || ""}</span>
			    </p>
			    <p class="coastal--type-body2">
				    <strong>Item Number:</strong> 
				    <span class="product-details__item-code">${item.itemnumber || ""}</span>
			    </p>
                <div class="cart-product__links">
                    <a href="#" class="coastal--type-link redLink cart-product__links--first"
                        data-diacart-remove-from-cart
                        data-diacart-item-id="${id || ""}"
                    >
                        ${options.removeFromCartBtnText || ""}
                    </a>
                </div>
            </div>
        </div>
        <div class="coastal--col-md-3 coastal--col-lg-3 coastal--col-xl-2 ">
            <div class="cart-product__details">
                <div class="cart-product__order-numbers">
                    <div class="cart-product__detail-group">
                        <p class="coastal--type-caption cart-product__details-small">
                            Price
                        </p>
                        ${options.itemHasPrice && item.price
                    ? html`
                          <p class="cart-product__price cart__important-text">
                            ${options.currency || ""}${parseFloat(item.price).toFixed(2) || ""}
                          </p>
                        `
                    : ""}
                    </div>
                    <div class="cart-product__detail-group diacart-counter">
                        ${options.itemHasQuantity && item.quantity
                    ? html`
                        <p class="coastal--type-caption cart-product__quantity-text cart-product__details-small cart-product__price--small">
                            ${options.totalQuantityText}:</p>
                        <div class="cart-product__quantity diacart-item-option__value diacart-counter__container">
                            <!--
                            <button
                                class="diacart-grid__col diacart-grid__col--auto diacart-counter__btn diacart-counter__plus-btn"
                            >
                                +
                            </button>
                            -->
                            <input
                                class="cart-product__select diacart-grid__col diacart-grid__col--flex diacart-counter__input"
                                type="number"
                                name="quantity"
                                data-diacart-quantity-input
                                data-diacart-item-id="${id || ""}"
                                .value="${item.quantity || 1}"
                            />
                            <!--
                            <button
                                class="diacart-grid__col diacart-grid__col--auto diacart-counter__btn diacart-counter__minus-btn"
                            >
                                -
                            </button>
                            -->
                              `
                    : ""}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    `;
        }, // cart item template as a function, used in 'template' function
        miniTemplate: diacartObject => html`<!-- mini cart -->`, // mini cart template as a function
        subtotalPriceTemplate: diacartObject => {
            const { options, totalPrice } = diacartObject;
            return (options.currency + "" || "") + parseFloat(totalPrice).toFixed(2);
        }, // subtotal price template as a function
        taxTemplate: diacartObject => {
            const { options } = diacartObject;
            return (options.currency + "" || "") + parseFloat(options.tax).toFixed(2);
        }, // tax template as a function
        discountTemplate: diacartObject => {
            const { options } = diacartObject;
            return (options.currency + "" || "") + parseFloat(options.discount).toFixed(2);
        }, // discount template as a function
        totalPriceTemplate: diacartObject => {
            const { options, totalPrice } = diacartObject;
            return (options.currency + "" || "") + parseFloat(totalPrice + options.tax - options.discount).toFixed(2);
        }, // total price template as a function
        totalQuantityTemplate: diacartObject => {
            const { totalQuantity } = diacartObject;
            return parseInt(totalQuantity);
        } // total quantity template as a function
    });

    function InitPromo() {
        window.$('#apply-promo-code').on('click', function (e) {
            e.preventDefault();
            applyPromo = true;
            diacart.order();
        });

        setDiscount();
    }

    CalculateTax();
    InitPromo();

    function AddAntiForgeryToken(data) {
        data.__RequestVerificationToken = document.getElementsByName("__RequestVerificationToken")[0].value;
        return data;
    }

    function BillingOptionCheck() {
        var billingChoices = document.getElementsByName("billing-choice");

        var choice = "";

        for (var i = 0, length = billingChoices.length; i < length; i++) {
            if (billingChoices[i].checked) {
                choice = billingChoices[i].value;

                break;
            }
        }

        return choice;
    }

    function PushGTM(order) {
        window.dataLayer = window.dataLayer || [];

        //console.log('gtm obj: ', window['google_tag_manager']);
        //console.log('order: ', order);

        if (order.Items && window['google_tag_manager']) {

            var event = { 'event': 'checkout' };

			event.ecommerce = {
				'purchase': {
					'actionField': {
						'id': order.Response.Order.OrderNumber,
						'affiliation': 'Coastal Pet Online',
						'revenue': order.Response.Revenue,
						'shipping': '',
                        'tax': order.Tax,
                        'coupon': order.Response.Order.PromoCode
					},
					'products': []
				}
            };

            for (var i = 0; i < order.Items.length; i++) {
                var instance = {
                    'id': order.Items[i].Id,
                    'name': order.Items[i].Name,
                    'sku': order.Items[i].ItemNumber,
                    'price': order.Items[i].Price,
                    'category': order.Items[i].Name,
                    'quantity': order.Items[i].Quantity
                };

                event.ecommerce.purchase.products.push(instance);
            }

            window.dataLayer.push(event);

            //window.dataLayer.push({
            //	'eventCallback': function () {
            //		console.log('datalayer callback func');
            //	},
            //	'eventTimeout' : 2000
            //});
        }
    }

    function ProcessCart(cart) {
        let headers = {
            'RequestVerificationToken': window.coastal.tokenHeaderValue
        };
        cachios.post(window.coastal.submitOrderUrl, AddAntiForgeryToken(cart), { headers: headers, ttl: 1 })
            .then((response) => {

                cartStorage.removeItem('cartprocessing');
                isSubmitting = false;

                if (typeof (response.data.Response) !== 'undefined' && typeof (response.data.Response.ConfirmationUrl) !== 'undefined') {
                    //push the order info to Google Tag Manager
                    PushGTM(response.data);
                    cartStorage.removeItem('promo');
                    trackCartSubmit(response.data.Response.ConfirmationUrl);
                } else if (response.data.Approved === false) {
                    alert(response.data.Message);

                    let orderButton = document.querySelector("[data-diacart-order]");

                    orderButton.text = "Complete Order";
                    orderButton.classList.remove("cart__button-checkout--disabled");
                } else if (response.data.error !== '') {
                    alert(response.data.error);
                    let orderButton = document.querySelector("[data-diacart-order]");

                    orderButton.text = "Complete Order";
                    orderButton.classList.remove("cart__button-checkout--disabled");
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    function CalculateTax() {
        if (window.coastal.reviewcart) {
            let items = [];
            for (let item in diacart.storage._data) {
                items.push(diacart.storage._data[item]);
            }

            let promo = getPromo();
            let promoCode = (promo != null && promo.promoCode != null ? promo.promoCode : "");
            let cart = {
                items: items,
                promoCode: promoCode,
                response: {
                    order: {
                        address1: window.coastal.address.address1,
                        city: window.coastal.address.city,
                        state: window.coastal.address.state,
                        zip: window.coastal.address.zip
                    }
                }
            };

            let headers = {
                'RequestVerificationToken': window.coastal.tokenHeaderValue
            };

            cachios.post(window.coastal.gettaxurl, AddAntiForgeryToken(cart), { headers: headers })
                .then((response) => {
                    if (response.data !== null && response.data.tax > 0) {
                        diacart.options.tax = response.data.tax;
                        diacart.render();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    // events
    function onAdd(addedItemId) {
        //console.log("onAdd arguments: ", addedItemId);
    }

    function onUpdate(updatedItemId, updateObj) {
        // recalculate tax
        setDiscount();
        CalculateTax();
        // console.log("onUpdate arguments: ", updatedItemId, updateObj);
    }

    function onStorageUpdate(newValue, prevValue) {
        setDiscount();
        console.log("onStorageUpdate arguments: ", newValue, prevValue);
        sessionStorage.setItem("newValue", JSON.stringify(newValue));
        sessionStorage.setItem("prevValue", JSON.stringify(prevValue));
            
        if (Object.keys(newValue).length == Object.keys(prevValue).length) {
            for (var i = 0; i < Object.keys(newValue).length; i++) {
                if (Object.values(newValue)[i].quantity != Object.values(prevValue)[i].quantity) {
                    trackCartQtyUpdate(Object.values(newValue)[i].id, Object.values(newValue)[i].quantity);
                }
            }
        }
    }

    function onRemove(removedItemObj) {
        setDiscount();
        // console.log("onRemove arguments: ", removedItemObj);
        //Resync the table from the cart
        var prevValue = JSON.parse(sessionStorage.getItem("prevValue"));
        var newValue = JSON.parse(sessionStorage.getItem("newValue"));
        for (var i = 0; i < Object.keys(prevValue).length; i++) {
            var theId = Object.values(prevValue)[i].id;
            //look for the id in the new value object, if not found it was removed
            var isFound = false;
            var x = 0;
            for (x = 0; x < Object.keys(newValue).length; x++) {
                if (theId == Object.values(newValue)[x].id) {
                    isFound = true;
                }
            }
            if (!isFound) {
                trackCartRemove(Object.values(prevValue)[i].id);
                console.log("Removing Item from cmsCart: " + Object.values(prevValue)[i].id);
            }
        }

        // recalculate tax
        CalculateTax();
    }

    function priceRequests(items) {
        let requests = [];

        for (let item of items) {
            let request = cachios.post(window.coastal.getpriceurl, { ItemNumber: item.itemnumber })
                .then((response) => {
                    item.price = response.data;
                })
                .catch((error) => {
                    console.log(error);
                });

            requests.push(request);
        }

        return requests;
    }

    function updatePromo(promo) {
        if (promo.promoCode !== '' && promo.promoCode !== null) {
            window.$("#promo-error").hide();
            cartStorage.setItem('promo', JSON.stringify({ discount: promo.discount, promoCode: promo.promoCode }));
        } else {
            cartStorage.removeItem('promo');
            window.$("#promo-error").show();
        }
        setCartPromo(promo);
    }

    function clearCartPromo() {
        diacart.options.discount = 0;
        diacart.options.promoCode = "";
        diacart.render();
    }

    function getPromo() {
        return JSON.parse(cartStorage.getItem("promo"));
    }

    function setDiscount() {
        var promo = getPromo();
        setCartPromo(promo);
    }

    function setCartPromo(promo) {
        if (promo !== null) {
            if (promo.promoCode !== null && promo.promoCode !== '') {
                if (window.$('#promo-code').val() != promo.promoCode) {
                    window.$('#promo-code').val(promo.promoCode);
                }
                if (promo.discount > 0) {
                    diacart.options.discount = parseFloat(parseFloat(diacart.totalPrice).toFixed(2) * promo.discount / 100).toFixed(2);
                    diacart.options.promoCode = promo.promoCode;
                    diacart.render();
                }
            } else {
                clearCartPromo();
            }            
        } else {
            clearCartPromo();
        }
    }

    function onOrder(items) {
        if (BillingOptionCheck() === 'card') {
            checkoutForm.validate();
            if (!checkoutForm.valid()) {
                checkoutElem.scrollIntoView();
                return false;
            }
        }

        // console.log("onOrder arguments:", items);

        // Should only be hit if someone has been able to add to their cart, yet
        // still does not have an account #.
        if (accountId === "") {
            alert("Please contact Coastal Pet support to secure an account number.");
            return false;
        }

        if (isFinalStep === false) {
            if (typeof (window.coastal.getpriceurl) !== 'undefined') {
                let requests = priceRequests(items);

                Promise.all(requests)
                    .then(function (response) {
                        window.location = window.coastal.nextCartUrl;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            } else if (applyPromo) {
                let request = cachios.post(window.location.origin + '/umbraco/Surface/Promotion/Apply', { promoCode: window.$('#promo-code').val(), items: items })
                    .then((response) => {
                        if (response.data !== null) {
                            updatePromo(response.data);
                        } else {
                            console.error('no data');
                        }
                        applyPromo = false;
                        
                    })
                    .catch((error) => {
                        applyPromo = false;
                        console.log(error);
                    });
            } else {
                window.location = window.coastal.nextCartUrl;
            }
        } else {
            let orderButton = document.querySelector("[data-diacart-order]");

            orderButton.text = "Processing...";
            orderButton.classList.add("cart__button-checkout--disabled");

            if (isSubmitting === true) {
                return false;
            } else if (cartStorage.getItem('cartprocessing') !== null) {
                let cartProcessTime = new Date(cartStorage.getItem('cartprocessing'));
                let currentTime = new Date();

                if (diffMinutes(cartProcessTime, currentTime) < 15) {
                    return false;
                }
            }

            // If we make it this far, the cart should be submitted
            cartStorage.setItem('cartprocessing', new Date());
            isSubmitting = true;

            // let requests = priceRequests(items);

            let orderNote = document.getElementsByName("order-note")[0];
            let promo = getPromo();
            let promoCode = (promo != null && promo.promoCode != null ? promo.promoCode : "");

            let cart = {
                items: items,
                billing: BillingOptionCheck(),
                subscribe: mailingList.is(':checked'),
                firstName: cardFirstName.val(),
                lastName: cardLastName.val(),
                cardNumber: cardNumber.val(),
                expMonth: expMonth.val(),
                expYear: expYear.val(),
                cvv: cvv.val(),
                promoCode: promoCode,
                note: typeof orderNote !== "undefined" ? orderNote.value : ""
            };

            /*Promise.all(requests)
                .then(function (response) {*/
                    ProcessCart(cart);
                /*})
                .catch(function (error) {
                    console.error(error);
                });*/
        }
    }

    function onClear() {
        // console.log("onClear has no arguments");
    }

    function onRender() {
        //console.log("onRender has no arguments");
    }

    // Determine the difference in minutes between two Datetime objects
    function diffMinutes(dt2, dt1) {
        var diff = (dt2.getTime() - dt1.getTime()) / 1000;
        diff /= 60;
        return Math.abs(Math.round(diff));
    }

    function trackCartAdd(transformedProductData) {
        var postUrl = "/umbraco/surface/CartData/Add?";
        postUrl = postUrl + "accountId=" + accountId;
        postUrl = postUrl + "&itemId=" + transformedProductData.id;
        postUrl = postUrl + "&imageLink=" + transformedProductData.image;
        postUrl = postUrl + "&itemNumber=" + transformedProductData.itemnumber;
        postUrl = postUrl + "&pageLink=" + transformedProductData.link;
        postUrl = postUrl + "&itemDescription=" + transformedProductData.name;
        postUrl = postUrl + "&itemPrice=" + transformedProductData.price;
        postUrl = postUrl + "&itemQty=" + transformedProductData.quantity;
        cartDataPost(postUrl);
    }

    function trackCartSync(transformedProductData) {
        var postUrl = "/umbraco/surface/CartData/syncCartItem?";
        postUrl = postUrl + "accountId=" + accountId;
        postUrl = postUrl + "&itemId=" + transformedProductData.id;
        postUrl = postUrl + "&imageLink=" + transformedProductData.image;
        postUrl = postUrl + "&itemNumber=" + transformedProductData.itemnumber;
        postUrl = postUrl + "&pageLink=" + transformedProductData.link;
        postUrl = postUrl + "&itemDescription=" + transformedProductData.name;
        postUrl = postUrl + "&itemPrice=" + transformedProductData.price;
        postUrl = postUrl + "&itemQty=" + transformedProductData.quantity;
        cartDataPost(postUrl);
    }

    function tableSyncPost() {
        var postUrl = "/umbraco/surface/CartData/syncTable?";
        postUrl = postUrl + "accountId=" + accountId;
        cartDataPost(postUrl);
    }
    function trackCartSubmit(confirmationUrl) {
        var postUrl = "/umbraco/surface/CartData/Checkout?";
        postUrl = postUrl + "accountId=" + accountId;
        cartDataPost(postUrl, confirmationUrl);
    }
    function trackCartClear() {
        var postUrl = "/umbraco/surface/CartData/Clear?";
        postUrl = postUrl + "accountId=" + accountId;
        cartDataPost(postUrl);
    }

    function trackCartRemove(itemId) {
        var postUrl = "/umbraco/surface/CartData/RemoveItem?";
        postUrl = postUrl + "accountId=" + accountId;
        postUrl = postUrl + "&itemId=" + itemId.toString();
        cartDataPost(postUrl);
    }

    function trackCartQtyUpdate(itemId, newQty) {
        var postUrl = "/umbraco/surface/CartData/UpdateQty?";
        postUrl = postUrl + "accountId=" + accountId;
        postUrl = postUrl + "&itemId=" + itemId.toString();
        postUrl = postUrl + "&newQty=" + newQty.toString();
        cartDataPost(postUrl);

    }
    function cartDataPost(postUrl, confirmationUrl) {
        try {
            console.log('Pushing data to cartdata contoller');
            var request = new XMLHttpRequest();
            var urlServer = window.location.protocol + '//' + window.location.hostname;
            if (window.location.port != "80" && window.location.port != "443") {
                urlServer = urlServer + ":" + window.location.port;
            }
            var requestPath = urlServer + postUrl;
            request.open("GET", requestPath, false);
            //request.timeout = 30; //Can't set synch request timeout
            request.send(null);
            console.log(request.response);
        } catch (err) {
            console.error(err);
        }

        if (typeof confirmationUrl !== 'undefined') {
            window.location = confirmationUrl;
        }
    }

    
    function syncCart(clearCart) {
        //Update the shopping cart with data stored in cmsCart
        try {
            console.log('Syncing Cart Data');
            //Get data from cmsCart table
            var postUrl = '/umbraco/surface/CartData/JSONDetails?accountId=' + accountId;
            var request = new XMLHttpRequest();
            var urlServer = window.location.protocol + '//' + window.location.hostname;
            if (window.location.port != "80" && window.location.port != "443") {
                urlServer = urlServer + ":" + window.location.port;
            }
            var requestPath = urlServer + postUrl;
            request.open("GET", requestPath, false);
            //request.timeout = 30; //Can't set synch request timeout
            request.send(null);
        } catch (err) {
            console.error(err);
            return;
        }

        console.log("cmsCart Data:");
        console.log(request.response);

        if (clearCart == true) {
            diacart.clear();
        }

        //Loop through data, check if data is in cart
        JSON.parse(request.response).forEach(function (element) {
            if (!diacart.hasItem({ id: "" + element.ItemId + "" })) {

                //Add to the cart
                console.log("Item Not Found - Adding Item to Cart - " + element.ItemId);
                let productData = {};
                productData.id = element.ItemId;
                productData.itemnumber = element.ItemNumber;
                productData.name = element.ItemDescription;
                productData.price = element.ItemPrice;
                productData.image = element.ImageLink;
                productData.link = element.PageLink;
                productData.quantity = parseInt(element.ItemQty);
                diacart.add(productData);
            }
        });

        //set as in sync to keep additional processing from occuring
        sessionStorage.setItem("isCartSync", "1");
    }

    function tableSync() {
        //Sync CMS Table with Cart Data
        try {
            var cartData = diacart.storage._data;
            console.log("Diacart Primary: " + diacart.storage.name);
            console.log(cartData);

            for (var i = 0; i < Object.keys(cartData).length; i++) {
                var element = cartData[i + 1];

                let productData = {};
                productData.id = element.id;
                productData.itemnumber = element.itemnumber;
                productData.name = element.name;
                productData.price = element.price;
                productData.image = element.image;
                productData.link = element.link;
                productData.quantity = parseInt(element.quantity);
                //Add item to session storage for processing
                trackCartSync(productData);
            }

            //Run Cart to Table Sync
            tableSyncPost();

        } catch (e) {
            console.error(e);
        }
    }

    diacart.on("add", onAdd); // will be called at every product adding
    diacart.on("update", onUpdate); // will be called at every product updating
    diacart.on("storageUpdate", onStorageUpdate); // will be called at every storage updating
    diacart.on("remove", onRemove); // will be called at every removing product from cart
    diacart.on("order", onOrder); // will be called at every ordering
    diacart.once("clear", onClear); // will be called only once when clearing the cart
    diacart.on("render", onRender); // will be called at every rerendering

    //CartData Sync - Check for items that need added to the cart
    if (accountId != "" && sessionStorage.getItem("isCartSync") != "1") {
        //Add current cart data to the table if needed
        //2020.06.26 - per Chris per Dana - Don't add the existing items to the table - clear cart, then load from the table
        //tableSync();
        //Add current table data to the cart if needed
        syncCart(true);
    }

    if (paymentOptions.length > 0) {
        function showHideCC() {
            if (window.$('input[name="billing-choice"]:checked').val() === 'card') {
                ccDivs.forEach((item, index) => {
                    window.$(item).show().attr("aria-hidden", "false");
                });
            } else {
                ccDivs.forEach((item, index) => {
                    window.$(item).hide().attr("aria-hidden", "true");
                });
            }
        }
        showHideCC();

        window.$('input[name="billing-choice"]').on('change', function () {
            showHideCC();
        });
    }

    addToCartButtons.forEach((item, index) => {
        item.addEventListener('click', e => {
            e.preventDefault();

            let button = e.currentTarget;

            let productData = button.dataset;

            let transformedProductData = {};
            transformedProductData.id = productData.upc;
            transformedProductData.itemnumber = productData.itemnumber;
            transformedProductData.name = productData.name;
            transformedProductData.price = productData.price.charAt(0) === "$" ? productData.price.substring(1) : productData.price;
            transformedProductData.image = productData.imagepath;
            transformedProductData.link = productData.link;
            transformedProductData.quantity = parseInt(productData.quantity);

            diacart.add(transformedProductData);

            //push the cart data to the controller - RS Three30Group v2020.05.27
            trackCartAdd(transformedProductData);

            //Commenting out this if statement stops redirect to cart page - CS 
            /*if (typeof window.coastal !== "undefined" && typeof window.coastal.nextCartUrl !== "undefined") {
                window.location = window.coastal.nextCartUrl;
            }*/
        });
    });

    window.addEventListener('coastal.addtocart', function (e) {
        if (typeof e.detail !== 'undefined') {
            let productData = e.detail.product;
            let quantity = e.detail.quantity;

            let transformedProductData = {};
            transformedProductData.id = productData.UPC_Code_with_Check_Digit;
            transformedProductData.itemnumber = productData.Item_Number_Manufacturer_Number;
            transformedProductData.name = productData.Name_without_Special_Characters;
            transformedProductData.price = productData.Price.charAt(0) === "$" ? productData.Price.substring(1) : productData.Price;
            transformedProductData.image = productData.ProductImage.salsify_url;
            transformedProductData.link = productData.Url;
            transformedProductData.quantity = parseInt(quantity);

            diacart.add(transformedProductData);

            //push the cart data to the controller - RS Three30Group v2020.05.27
            trackCartAdd(transformedProductData);
        }
    }, false);

    window.addEventListener('coastal.clearcart', function (e) {
        console.log('cart.js - Clearing cart.');
        trackCartClear();
        diacart.clear();
        //Check the cart is cleared
        if (diacart.length != 0) {
            diacart.clear();
        }
    }, false);

})();