/*jshint esversion: 6 */

export default (function () {
	const axios = require('axios');
	const Handlebars = require('handlebars');

	var sDataChecked = 'data-checked';	
	var animalTypeFacet,
		productTypeFacet,
		lifestyleFacet,
		breedsizeFacet,
		colorFacet,
		brandFacet,
        materialFacet,
        parentChildFacet,
		currentPage;

	const searchListing = document.getElementById('searchListing');
	const searchLoading = document.getElementById('searchLoading');

    var facetDict = {
        "Parent_Child": {
            "display": "Kits",
            "query": "parentChild"
        },
		"Animal_Type": {
			"display": "Animal Type",
			"query" : "animaltype"
		},
		"Product_Type": {
			"display": "Product Type",
			"query": "producttype"
		},
		"Lifestyle": {
			"display": "Lifestyle",
			"query": "lifestyle"
		},
		"Breed_Size_large_Small_All_Breeds_etc": {
			"display": "Breed Size",
			"query": "breedsize"
		},
		"Brand": {
			"display": "Brand",
			"query": "brand"
		},
		"Color": {
			"display": "Color",
			"query": "color"
		},
		"Material" : {
			"display" : "Material",
			"query" : "material"
		},
		"Keywords" : {
			"display" : "Keywords",
			"query" : "q"
		}
	};

	function Search() {
		var q = '';
		var searchUrl = searchPostUrl;

		if (currentPage === undefined) {
			currentPage = 1;
		}

		const params = new URLSearchParams(window.location.search);
		q = params.get('q');
		animalTypeFacet = params.get('animaltype');
		productTypeFacet = params.get('producttype');
		lifestyleFacet = params.get('lifestyle');
		breedsizeFacet = params.get('breedsize');
		colorFacet = params.get('color');
		brandFacet = params.get('brand');
		materialFacet = params.get('material');
        parentChildFacet = params.get('parentChild');

		console.log(searchUrl);

		//display loading message
		RenderLoadingMessage(true);

		axios.post(
			searchUrl,
			{
				searchText: q,
				animalTypeFacet: animalTypeFacet,
				productTypeFacet: productTypeFacet,
				lifestyleFacet: lifestyleFacet,
				breedsizeFacet: breedsizeFacet,
				colorFacet: colorFacet,
				brandFacet: brandFacet,
                materialFacet: materialFacet,
                parentChildFacet: parentChildFacet,
				currentPage: currentPage
			})
			.then(function (response) {
				console.log(response.data);

				RenderFacets(response);

				RenderListing(response.data.Products, response.data.SearchId, response.data.SearchServiceName);

				RenderPagination(response.data.Count);

			})
    }

    const pagination = document.getElementById('searchPagination');

    if (pagination === null) {
        return false;
    }

	//listen for pagination selections
    pagination.addEventListener('click', e => {
		var self = e.target;

		if (self.dataset.page) {
			console.log(self.dataset.page);

			currentPage = self.dataset.page;
			AddOrUpdateQueryParam("currentPage", currentPage);

			Search();
		}
	});

	//listen for facet selections
	document.getElementById('searchFacets').addEventListener('click', e => {
		var self = e.target;
		var fg = self.dataset.facetgroup;

		console.log(self, " | ", fg);

		if (fg) {
			//for toggling radio buttons
			setTimeout(function () {
				if (self.checked) {
					if (self.getAttribute(sDataChecked)) {
						self.checked = !self.checked;
						self.removeAttribute(sDataChecked);

						//remove from query string
						DeleteQueryParam(facetDict[fg].query);

						Search();
					} else {
						self.setAttribute(sDataChecked, 1);

						//add to query string
						AddOrUpdateQueryParam(facetDict[fg].query, self.value);

						Search();
					}
				} else {
					self.removeAttribute(sDataChecked);
				}
			}, 1);
			
			currentPage = 1;
			AddOrUpdateQueryParam("currentPage", 1);
		}
	});

	document.getElementById('searchFacets').addEventListener('change', function (e) {
		var self = e.target;

		if (self.classList.contains('sidebar-filter__radio')) {
			self.removeAttribute(sDataChecked);
		}
	});

	function AddOrUpdateQueryParam(param, value) {
		var params = new URLSearchParams(window.location.search);

		if (params.has(param)) {
			params.set(param, value);
		}
		else {
			params.append(param, value);
		}

		var newRelativePathQuery = window.location.pathname + '?' + params.toString();
		history.pushState(null, '', newRelativePathQuery);
	}

	function DeleteQueryParam(param) {
		var params = new URLSearchParams(window.location.search);

		params.delete(param);

		var newRelativePathQuery = window.location.pathname + '?' + params.toString();
		history.pushState(null, '', newRelativePathQuery);
	}

	function RenderPagination(resultsCount) {
		// Update the pagination
		var backPage = parseInt(currentPage) - 1;
		
		if (backPage < 1) {
			backPage = 1;
		}

		var pagination = {
			currentPage: parseInt(currentPage),
			totalPages: Math.ceil(resultsCount / 20), //divide by page amt
			startPage: parseInt(currentPage),
			backPage: parseInt(currentPage) - 1,
			forwardPage: parseInt(currentPage) + 1
		};

		console.log('pagination: ', pagination);

		var source = document.getElementById('searchPaginationTemplate').innerHTML;
		var template = Handlebars.compile(source);
		var html = template(pagination);

		AddOrUpdateQueryParam("currentPage", pagination.currentPage);

		document.getElementById('searchPagination').innerHTML = html;
	}

	function RenderFacets(data) {
		//move kits to first position
		//if (data.data.Facets.Parent_Child !== undefined) {
		//	if (data.data.Facets.Parent_Child.indexOf("Kit") > 0) {
		//		data.data.Facets.splice(data.data.Facets.indexOf('Kit'), 1);
		//		data.data.Facets.unshift('Kit');
		//	}
		//}

		//console.log("debug: ", data.data.Facets.indexOf("Parent_Child"));

		var source = document.getElementById('searchFacetsTemplate').innerHTML;
		var template = Handlebars.compile(source);
		var html = template(data);

		document.getElementById('searchFacets').innerHTML = html;

		//get all the param values
		var params = new URLSearchParams(window.location.search);
		var paramVals = [...params.values()];

		var filters = [].slice.apply(document.getElementsByClassName('sidebar-filter__radio'));
		var facetCategories = [].slice.apply(document.getElementsByClassName('sidebar-filter__filter-category'));

		filters.forEach((item, index) => {
			for (let p of paramVals) {
				//console.log(item.value + ' | ' + p);

				if (p === item.value) {
					item.checked = true;
					item.setAttribute(sDataChecked, 1);
				}
			}
		});

		facetCategories.forEach((item, i) => {
			var dict = facetDict[item.firstChild.data];
			item.firstChild.data = dict.display;
		});

		var keywordsInput = document.getElementById('searchKeywordsInput');

		if (params.get('q') !== '') {
			keywordsInput.value = params.get('q');
		}

		keywordsInput.addEventListener('keyup', e => {
			e.preventDefault();
			if (event.keyCode === 13) {
				AddOrUpdateQueryParam("q", e.target.value);
				Search();
			}
		});
	}

	function RenderLoadingMessage(show){
		if (show){
			searchListing.style.visibility = 'hidden';
			searchLoading.style.display = 'flex';
		}
		else {
			searchListing.style.visibility = 'visible';
			searchLoading.style.display = 'none';
		}
	}

	function RenderListing(data, SearchId, SearchServiceName) {
		var source = document.getElementById('searchListingTemplate').innerHTML;
		var template = Handlebars.compile(source);
		var html = template(data);

		//hide loading message
		RenderLoadingMessage(false);

		document.getElementById('searchListing').innerHTML = html;

		var productListingLink = document.querySelectorAll('.product-listing__link, .product-listing__title');

		productListingLink.forEach(function (attribute) {
			attribute.setAttribute("SearchId", SearchId);
			attribute.setAttribute("SearchServiceName", SearchServiceName);
			attribute.addEventListener('click', function (event) {
				var attribute = event.currentTarget;
				var itemNumber = attribute.attributes.itemNumber.nodeValue;
				var searchID = attribute.attributes.SearchID.nodeValue;
				var searchServiceName = attribute.attributes.SearchServiceName.nodeValue;
				var rank = attribute.attributes.rank.nodeValue;
				rank++;
				appInsights.trackEvent({
					name: "Click",
					properties: {
					SearchServiceName: searchServiceName,
					SearchId: searchID,
					ClickedDocId: itemNumber,
					Rank: rank
					}
				});
			})
		})
	}

	Handlebars.registerHelper('repeat', require('handlebars-helper-repeat'));

	Handlebars.registerHelper('if_eq', function (a, b, opts) {
		if (a === b)
			return opts.fn(this);
		else
			return opts.inverse(this);
	});

	Handlebars.registerHelper('if_neq', function (a, b, opts) {
		if (a !== b)
			return opts.fn(this);
		else
			return opts.inverse(this);
	});

	Handlebars.registerHelper('if_gt', function (a, b, opts) {
		if (a > b)
			return opts.fn(this);
		else
			return opts.inverse(this);
	});

	Handlebars.registerHelper('if_lt', function (a, b, opts) {
		if (a < b)
			return opts.fn(this);
		else
			return opts.inverse(this);
	});

	Handlebars.registerHelper('replaceHttp', function (replaceHttp) {
		return replaceHttp.replace('http://', 'https://');
	});

	window.onload = Search;
})();