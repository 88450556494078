(function(e) {
  var matches =
    e.matches ||
    e.matchesSelector ||
    e.webkitMatchesSelector ||
    e.mozMatchesSelector ||
    e.msMatchesSelector ||
    e.oMatchesSelector;
  !matches
    ? (e.matches = e.matchesSelector = function matches(selector) {
        var matches = document.querySelectorAll(selector);
        var th = this;
        return Array.prototype.some.call(matches, function(e) {
          return e === th;
        });
      })
    : (e.matches = e.matchesSelector = matches);
})(Element.prototype);
