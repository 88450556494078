export default (function () {
	const animalTypeButtons = [].slice.apply(document.getElementsByClassName('homepage__button-animal'));
	const featuredProductListings = [].slice.apply(document.getElementsByClassName('featured-products'));

    if (typeof animalTypeButtons === "undefined" || animalTypeButtons.length === 0) {
        return false;
    }

	animalTypeButtons[0].classList.add('homepage__call-to-action--inverted');

	animalTypeButtons.forEach((item, index) => {
		item.addEventListener('click', e => {
			e.preventDefault();

			animalTypeButtons.forEach((item, index) => {
				item.classList.remove('homepage__call-to-action--inverted')
			});

			e.currentTarget.classList.add('homepage__call-to-action--inverted');

			featuredProductListings.forEach((item, i) => {
				item.classList.remove('featured-products--hide');

				if (item.dataset.shopall !== e.currentTarget.dataset.animaltype) {
					item.classList.add('featured-products--hide');
					item.classList.remove('featured-products');
				}
			});
		});
	});

	featuredProductListings.forEach((item, index) => {
		if (index === featuredProductListings.length-1) {
			item.classList.add('featured-products--hide');
		}
	});
})();