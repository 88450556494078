import salsify from 'salsify-experiences-sdk';

export default (function () {
    const clientId = 's-6f4dff66-426e-4da9-a0bd-848131a30210';
    const idType = 'SKUWITHCHECKDIGIT';

    salsify.init({
        clientId,
        enhancedContent: { idType },
        languageCode: 'en-US',
        tracking: true
    });
})();